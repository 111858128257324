export default [
	{
		image: "sign-up.svg",
		imageAlt: "Sign-up",
		title: "Sign-up",
		description:
			"Sign-up within a minute and get access to your personal Miles Web affiliates account.",
	},
	{
		image: "wait.svg",
		imageAlt: "Wait for approval",
		title: "Wait for approval",
		description:
			"Protect your domain from expiring and accidentally being lost by enabling auto-renewal. Switch back to manual renewal at anytime. ",
	},
	{
		image: "enjoy.svg",
		imageAlt: "Enjoy",
		title: "Enjoy",
		description:
			"Once you find your perfect domain, lock it down to prevent unauthorized transfers. Unlock it for transfers at anytime. ",
	},
];
