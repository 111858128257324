import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Accordion from "../components/accordion";
import ImageRight from "../components/imageRight";
import Landing from "../components/landingSection";
import Features from "../DB/student-hostingF";
import Feature from "../components/featureBlock";
import FeaturesVPS from "../DB/vps-features";
import AccordionContent from "../DB/Accordion-encrypt-protection";
import Image from "../components/imageProcessing";
import StudentForm from "../components/student-form";
import IconList from "../components/SSL_COMP/WhatIsSsl";

function affiliate() {
	return (
		<Layout>
			<SEO
				keywords={[
					"shared hosting",
					"what is shared hosting",
					"what is a shared hosting",
					"shared hosting vs vpsHosting",
					"what's shared hosting",
					"shared hosting vs cloud hosting",
					"shared hosting best",
					"shared hosting vs wordpressHosting hosting",
					"shared hosting vs dedicated hosting",
					"shared hosting cheap",
					"shared hosting server",
					"shared hosting free",
					"shared hosting cpanel",
					"shared hosting with cpanel",
					"shared hosting provider",
					"shared hosting for wordpressHosting",
					"shared hosting wordpressHosting",
					"shared hosting website",
					"shared hosting linux",
					"shared hosting or vpsHosting",
					"shared hosting price",
					"shared hosting offers",
					"shared hosting nginx",
					"shared hosting ram",
					"what is shared hosting service",
				]}
				title="Student hosting Program"
				image={"/hostylus-card-preview.jpg"}
				description="Get started with affordable Shared Hosting packages that help take your website to the top with free migration and a 100% guarantee of zero data loss."
			/>
			<Landing
				header="Student Hosting"
				desc="Boost your online reach without worrying about your website’s 
hosting plans. Our wide range of options will help you achieve 
your intended goals."
				btnURL="#emailForm"
				hideSecondary_btn={"hidden"}
				btnText={"join us now"}
				image={
					<Image
						image={"studentLAnding.svg"}
						alt={"- a blue student backpack with hostylus logo inside"}
					/>
				}
			/>
			<section
				style={{
					background: "linear-gradient(180deg, #2563EB 0%, #243B6D 100%)",
				}}
				className="w-full px-16 flex mobile:flex-col  m-auto py-24 mobile:p-5 "
			>
				<div className=" w-1/2 mobile:w-full py-10 mobile:py-2">
					<h2 className="text-4xl text-white font-bold px-16 mobile:text-center mobile:px-8 mt-8 mobile:text-2xl">
						Apply with your university email only
					</h2>
					<p className="mobile:text-sm text-white text-lg font-normal	mobile:text-center mobile:px-8 px-16 my-4 ">
						{" "}
						Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
						purus sit amet luctus venenatis, lectus magna fringilla urna,
						porttitor rhoncus dolor purus non enim praesent elementum facilisis
						leo, vel
					</p>
					<div className="text-white">
						<IconList
							className="text-white"
							header="Lorem ipsum dolor sit amet"
							tickC="white"
						/>
						<IconList
							className="text-white"
							header="Lorem ipsum dolor sit amet"
							tickC="white"
						/>
						<IconList
							className="text-white font-light "
							header="Lorem ipsum dolor sit amet"
							tickC="white"
						/>
					</div>
				</div>
				<div className=" w-1/2 mobile:w-full ">
					<StudentForm />
				</div>
			</section>
			<section className="features max-w-6xl mx-auto my-16 mobile:px-3 tablet:px-4 laptop:px-6">
				<h2 className="text-4xl text-black font-bold text-center">
					How Does it Work?
				</h2>
				<h4 className="text-gray-400 text-center text-xl px-16 my-3 mobile:px-4 mobile:text-2xl">
					We like to make things simple. As long as you are a current student,
					you can enter in your .edu address above, which will unlock the order
					process for a shared web hosting account at up to 50% off!
				</h4>
				<div className="features grid grid-cols-3 gap-3 flex justify-items-center mt-6 mobile:grid-cols-1 py-8 tablet:grid-cols-2 tablet:mx-5">
					{Features.map((items, key) => (
						<Feature
							key={key}
							image={items.image}
							header={items.title}
							desc={items.description}
							alt={items.title}
						/>
					))}
				</div>
			</section>
			<ImageRight
				image={
					<Image
						image={"studentEarningsvg.svg"}
						alt={"an image with boxes showing the advantages users will get"}
					/>
				}
				header={"What Do I Get?"}
				desc={
					"Easy to use, powerful, Linux-based web hosting that works with any Windows, Linux or Mac based PC. You also receive 24/7/365 U.S. based technical support - always there to help, even when you are pulling an all-nighter. All of this is yours for 50% off or more. That's over $50 in savings."
				}
				btnText={"Learn more"}
				btnLink={"#"}
				hideShopNow={"hidden"}
			/>

			<section className="features max-w-6xl mx-auto my-16 mobile:px-3 tablet:px-4 laptop:px-6">
				<h2 className="text-4xl text-black font-bold text-center">FEATURES</h2>
				<div className="features grid grid-cols-3 gap-3 flex justify-items-center mt-6 mobile:grid-cols-1 py-8 tablet:grid-cols-2 tablet:mx-5">
					{FeaturesVPS.map((items, key) => (
						<Feature
							key={key}
							image={items.image}
							header={items.title}
							desc={items.description}
							alt={items.title}
						/>
					))}
				</div>
			</section>

			<section className="my-16 h-72 bg-blue-600 flex flex-wrap content-center justify-center">
				<div className="max-w-4xl text-center">
					<h2 className="text-center text-white text-4xl font-bold uppercase mobile:text-3xl">
						Join us for free
					</h2>
					<p className="text-white text-md my-6 mobile:px-4 tablet:px-6">
						We make it easy for you.
					</p>
					<a href="#">
						<button className="px-6 inline py-3 uppercase bg-white shadow-md ml-1 text-blue-600 rounded-3xl font-medium text-sm">
							get started
						</button>
					</a>
				</div>
			</section>
			<section className="acc py-16 grid justify-items-center">
				<div className=" px-8 max-w-2xl">
					<h2 className="text-center text-black mb-16 text-4xl font-bold uppercase">
						Have a Questions?
					</h2>
					{AccordionContent.map((items, index) => (
						<Accordion
							key={index}
							title={items.title}
							content={items.content}
						/>
					))}
				</div>
			</section>
		</Layout>
	);
}
export default affiliate;
