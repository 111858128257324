import React from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
export default function StudentForm() {
	const { register, handleSubmit } = useForm();
	const onSubmit = (data) => {
		axios
			.post(
				"https://sheet.best/api/sheets/f657fcf8-bfd5-43a7-8c20-77b9db149b1f",
				data
			)
			.then((data) => {
				console.log(data);
			});
	};
	return (
		<div className="w-full max-w-lg m-auto text-left mt-10">
			<form
				className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
				onSubmit={handleSubmit(onSubmit)}
			>
				<div className="">
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="username"
					>
						Full name
					</label>
					<input
						className="shadow appearance-none border border-gray-100 bg-gray-50 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
						id="firstName"
						name="firstName"
						type="text"
						placeholder="Your name"
						{...register("firstName")}
					></input>
				</div>
				<div className="mb-6">
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="password"
					>
						Email
					</label>
					<input
						className="shadow appearance-none border border-gray-100 bg-gray-50 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
						id="email"
						type="email"
						name="email"
						placeholder="Email"
						{...register("email")}
					></input>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="password"
					>
						Phone number
					</label>
					<input
						className="shadow appearance-none border border-gray-100 bg-gray-50 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
						id="phoneNumber"
						type="number"
						name="phoneNumber"
						placeholder="Phone number"
						{...register("phoneNumber")}
					></input>
					<label
						className="block text-gray-700 text-sm font-bold mb-2"
						htmlFor="password"
					>
						Purpose
					</label>
					<select
						{...register("purpose")}
						name="purpose"
						className="shadow appearance-none border border-gray-100 bg-gray-50 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
					>
						<option value="test">Test</option>
						<option value="school">School</option>
						<option value="Graduation">Graduation project</option>
						<option value="others">others</option>
					</select>

					<input type="checkbox" id="terms" name="terms" value="terms"></input>
					<label htmlFor="terms" className="text-sm text-gray-500 ml-2">
						I agree to the{" "}
						<a className="text-blue-500" href="#">
							terms and conditions
						</a>
					</label>
					<br></br>
					<input
						type="checkbox"
						id="alerts"
						name="alerts"
						value="alerts"
						{...register("alerts")}
					></input>
					<label htmlFor="alerts" className="text-sm text-gray-500 ml-2">
						Send me the latest deal alerts
					</label>
					<br></br>
				</div>
				<div className="flex items-center justify-between">
					<button
						className="bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
						type="submit"
					>
						Submit
					</button>
				</div>
			</form>
		</div>
	);
}
